export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '/main',
        redirect: '/',
        component: () => import('@/layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@views/Dashboard.vue'),
                meta: {
                  title: 'Dashboard',
                  authRequired: true,
                }
            },
            {
                path: '/account',
                name: 'account',
                component: () => import('@views/account/UserEdit.vue'),
                meta: {
                  title: 'Account',
                  authRequired: true,
                },
            },
            {
              path: '/applications',
              name: 'applications',
              component: () => import('@views/applications/Applications.vue'),
              meta: {
                title: "Applications",
                authRequired: true,
              }
            },
            {
              path: '/applications/:applicationId(\\d+)',
              name: 'application-show',
              component: () => import('@views/applications/ApplicationShow.vue'),
              meta: {
                title: "View Application",
                authRequired: true,
              },
            },
            {
                path: '/users',
                name: 'users-list',
                component: () => import('@views/users/UserList.vue'),
                meta: {
                  title: "Users List",
                  authRequired: true,
                },
            },
            {
              path: '/users/:userId(\\d+)',
              name: 'user-view',
              component: () => import('@views/users/UserShow.vue'),
              meta: {
                title: "View User",
                authRequired: true,
              },
            },
            {
              path: '/collections',
              name: 'collections',
              component: () => import('@views/collections/CollectionList.vue'),
              meta: {
                title: "Collections",
                authRequired: true,
              }
            },
            {
              path: '/collections/:collectionId(\\d+)',
              name: 'collection-show',
              component: () => import('@views/collections/CollectionShow.vue'),
              meta: {
                title: "View Collection",
                authRequired: true,
              },
            },
            {
              path: '/voter-identities',
              name: 'voter-identities',
              component: () => import('@views/voter-identities/VoterIdentities.vue'),
              meta: {
                title: "VoterIdentities",
                authRequired: true,
              }
            },
            {
              path: '/notifications',
              name: 'notifications',
              component: () => import('@views/notifications/NotificationList.vue'),
              meta: {
                title: "Notifications",
                authRequired: true,
              }
            },
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '/full',
        component: () => import('@/layouts/Full.vue'),
        children: [
          {
            path: '/login',
            name: 'login',
            component: () => import('@/views/pages/Login.vue'),
            meta: {
              guestRequired: true,
              title: "Login"
            }
          },
          {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/pages/Error404.vue'),
            meta: {
              title: "404 - Page not Found"
            }
          },
          {
            path: '/error-500',
            name: 'error-500',
            component: () => import('@/views/pages/Error500.vue'),
            meta: {
              title: "500 - Server error"
            }
          },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '/:any(.*)',
        redirect: '/error-404'
    },

]